<template>
  <base-section>
    <section-department-contact
      v-for="(department, index) in departments"
      :key="index"
      :department="department"
    />
  </base-section>
</template>

<script>
  import { STORE_NAMESPACE_CONTACT } from '@/views/contact/Index.vue'
  import SectionDepartmentContact from '@/views/sections/reusable/DepartmentContact'
  import { mapState } from 'vuex'

  export default {
    name: 'SectionContactFeatures',
    components: { SectionDepartmentContact },
    computed: {
      ...mapState(STORE_NAMESPACE_CONTACT, [
        'translations',
      ]),
      departments () {
        return this.translations?.contactSectionsItems?.map(item => ({
          id: item.icon,
          ...item,
        }))
      },
    },
    data: () => ({
      departments2: [
        {
          title: 'DZIAŁ FINANSOWY',
          phone: '(0-33) 842-39-20 wew.113',
          email: 'dzialfinansowy@chemorozruch.pl',
          id: 'mdi-briefcase',
        },
        {
          title: 'DZIAŁ HANDLOWY',
          phone: '(0-33) 842-39-20 wew. 135',
          email: 'dzialhandlowy@chemorozruch.pl',
          id: 'mdi-chart-line',

        },
        {
          title: 'DZIAŁ PERSONALNY',
          phone: '(0-33) 842-39-20 wew. 108',
          email: 'dzialpersonalny@chemorozruch.pl',
          id: 'mdi-account',
        },
        {
          title: 'DZIAŁ ZAOPATRZENIA',
          phone: '(0-33) 842-39-20 wew. 138',
          email: 'dzialzaopatrzenia@chemorozruch.pl',
          id: 'mdi-truck',
        },
        {
          title: 'DZIAŁ REALIZACJI PROJEKTÓW',
          phone: '(0-33) 842-39-20 wew. 112',
          email: 'realizacjaprojektow@chemorozruch.pl',
          id: 'mdi-math-compass',
        },
        {
          title: 'PEŁNOMOCNIK DS. ZSZ',
          phone: '(0-33) 842-39-20 wew. 130',
          email: 'pelnomocnikzsz@chemorozruch.pl',
          id: 'mdi-account-tie',
        },
        {
          title: 'SPECJALISTA DS. BHP',
          phone: '(0-33) 842 39 20 wew.109',
          email: 'specjalistabhp@chemorozruch.pl',
          id: 'mdi-account-hard-hat',
        },
        {
          title: 'DZIAŁ TECHNICZNY',
          phone: '(0-33) 842-39-20 wew.103',
          email: 'dzialtechniczny@chemorozruch.pl',
          id: 'mdi-hammer-wrench',
        },
      ],
    }),
  }
</script>
