<template>
  <base-section id="about-our-product">
    <v-container :id="department.id">
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          md="4"
        >
          <base-title
            :title="department.title"
            class="text-uppercase text-center"
            space="1"
          />
        </v-col>
      </v-row>
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          md="4"
        >
          <v-row class="pl-4">
            <base-avatar
              icon="mdi-phone"
              :outlined="false"
              color="primary"
              dark
              size="48"
            />
            <!-- Do not use v-html for user -->
            <!-- provided values -->
            <div
              class="line-height-3 pl-8 "
              v-html="department.phone"
            />
          </v-row>
        </v-col>
      </v-row>
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          md="6"
        >
          <v-divider
            class="ma-2"
          />
        </v-col>
      </v-row>
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          md="4"
        >
          <v-row class="pl-4">
            <base-avatar
              icon="mdi-email"
              :outlined="false"
              color="primary"
              dark
              size="48"
            />
            <!-- Do not use v-html for user -->
            <!-- provided values -->
            <div
              class="line-height-3 pl-8 "
              v-html="department.email"
            />
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionHeadedDescription',
    props: {
      department: {
        type: Object,
      },
    },
  }
</script>
